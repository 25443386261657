<template>
    <v-card v-if="typeof result.treeValue != 'undefined'">
        <v-card-title
                class="primary white--text body-2"
                v-if="typeof result.tooltip != 'undefined' && result.tooltip == false"
        >
            <div id="wrapper">
                <div id="c1">
                  {{
                    extraAttr.row["order"] +
                    ":" +
                    extraAttr.row["usedTime"] +
                    ":" +
                    truncateStr(extraAttr.row["c_type"],100)
                  }}
                </div>
                <div id="c2">
                    <v-btn
                            icon
                            dark
                            @click="$store.commit('closeDialog', result.dialogRef)"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </div>
            </div>
        </v-card-title>

        <!-- {{ extraAttr.row }}-->
        <v-card-text v-if="!empty(inputArray)">
            <v-row
                    v-if="typeof result.tooltip != 'undefined' && result.tooltip == false"
            >
                <v-col cols="9" v-if="type != 'INFO'">
                    <div class="searcharea d-block" id="packetSearchArea">
                        <div class="mb-3"></div>
                        <searchInput
                                v-model="localSearch"
                                @input="$emit('input', localSearch)"
                        ></searchInput>
                    </div>
                </v-col>
                <v-col v-if="type != 'INFO'">
                    <div class="pt-4"></div>
                    <v-icon
                            size="32"
                            color="primary"
                            title="previous"
                            @click.stop="$emit('item-clicked', 'previous')"
                    >mdi-arrow-left-circle
                    </v-icon>
                    <v-icon
                            size="32"
                            color="primary"
                            title="next"
                            @click.stop="$emit('item-clicked', 'next')"
                    >mdi-arrow-right-circle
                    </v-icon>
                    <v-icon
                            v-if="type==='HTTP'"
                            size="32"
                            color="primary"
                            title="Download the packet"
                            @click="downloadPacketFile"
                    >mdi-file-download
                    </v-icon>
                </v-col>
            </v-row>
            <div v-if="typeof inputArray === 'object'" class="popupContainer mt-3 ">
                <div v-if="Object.keys(inputArray).length === 0">
                    {{ language.componentLabel.labelPacketEmpty }}
                </div>
                <div v-else class="tree">
<!--                    <recursiveTreeBuilder
                            :inputArray="packetinfo"
                            :attrsNames="attrsNames"
                            :type="type"
                            :attrEnum="attrEnum"
                            :localSearch="localSearch"
                    ></recursiveTreeBuilder>
                    <recursiveTreeBuilder
                            :inputArray="packet263"
                            :attrsNames="attrsNames"
                            :type="type"
                            :attrEnum="attrEnum"
                            :localSearch="localSearch"
                    ></recursiveTreeBuilder>-->
                    <recursiveTreeBuilder
                            :inputArray="inputArray"
                            :attrsNames="attrsNames"
                            :type="type"
                            :attrEnum="attrEnum"
                            :localSearch="localSearch"
                    ></recursiveTreeBuilder>
                </div>
            </div>
            <div v-else style="word-wrap: break-word">
                <div
                        v-for="inputArrayValue in getInputArray(inputArray)"
                        :key="inputArrayValue"
                >
                    <div v-if="checkJSON(inputArrayValue)" class="tree">
                        <recursiveTreeBuilder
                                :inputArray="JSON.parse(inputArrayValue)"
                                :attrsNames="attrsNames"
                                :type="type"
                                :attrEnum="attrEnum"
                                :localSearch="localSearch"
                        ></recursiveTreeBuilder>
                    </div>
                    <span v-else>{{ inputArrayValue }}</span>
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>
<script>
import dictionaryService from "@/js/services/DictionaryService";
import {checkJSON, downloadFile, radiusAttrNames, truncateStr} from "@/js/helper.js";
    //import {checkJSON} from "@/js/helper.js";
    import recursiveTreeBuilder from "@/components/legacy/recursiveTreeBuilder.vue";
    import searchInput from "@/commonComponents/searchInput.vue";

    export default {
        components: {
            recursiveTreeBuilder,
            searchInput,
        },
        data: function () {
            return {
                localSearch: "",
                radiusAttributesCollection:[]
            };
        },
        props: ["result", "extraAttr", "row"],
        methods: {
            truncateStr: truncateStr,
            checkJSON: checkJSON,
            getInputArray(inputArray) {
                //console.log(inputArray);
                return inputArray.split("\n");
            },
            downloadPacketFile() {
                 //[testname(max 20 characters)] [teststarttime] [Step Nr] [packet IN][packet OUT].txt
                let testcaseFullName = document.getElementById("pageTitle").textContent.replace("Test Info - ","");
                let testName = testcaseFullName.substring(0, 20);
                let packet = "";
                if(this.extraAttr.row.signDirection==='in'){
                  packet = "Packet In";
                }else if(this.extraAttr.row.signDirection==='out'){
                  packet = "Packet Out";
                }
                 let rowTimeMS = this.extraAttr.row['usedTime'];
                 let rowTime = rowTimeMS.split('.');
                 let packetTime = rowTime[0].replaceAll(':','');
                 let startDateTime = document.getElementById("startTime").textContent.split(" ");
                 let startDate = startDateTime[0].replaceAll('-','');
                 let order = this.extraAttr.row['order'];
                 //let packetType = document.getElementById("rowID_"+order).textContent.substring(0, 10);
                 let packetType = this.extraAttr.row['c_type'];
                 //console.log(packetType);
                 let source = this.extraAttr.row['direction'];
                 let destination = this.extraAttr.row['destination'];
                 let fileName = testName+" "+startDate+"_"+packetTime+" No "+ order +" "+packetType+" "+packet;
                //console.log(fileName);
                 let acctopusInfo = "Acctopus Degust Packet Information (https://degust.acctopus.com)\n" +
                     "Test Name:"+testcaseFullName+"\n" +
                     "Packet Type: "+packetType+"\n" +
                     "Source: "+source+"\n" +
                     "Destination: "+destination+"\n" +
                     "Time: "+rowTimeMS+"\n\n";
                this.$delete(this.inputArray, "dataType");//we do not need!
              let newSource = {};
              let newDataVal = {};
             //console.log(this.inputArray);
              if (typeof this.inputArray.data != "undefined" && typeof this.inputArray.data == "string") { //some time BE was giving string! it not correct anyhow we have to workaround!
                newDataVal = JSON.parse(this.inputArray.data);
                let newInputArray = {...this.inputArray}
                this.$delete(newInputArray, "data");
                newSource = { ...newInputArray,  data: newDataVal }
              }else {
                newSource = this.inputArray;
              }
              downloadFile(acctopusInfo+JSON.stringify(newSource,null,2),fileName.trim()+".txt",'txt');
            },
            getAttributes() {
            dictionaryService.getRadiusAttributes((attributes) => {
              this.radiusAttributesCollection = radiusAttrNames(attributes);
            });
          },
        },
       mounted() {
          if(this.type=="RADIUS"){
            this.getAttributes();
          }
       },
        computed: {

            treeValue() {
                return this.result.treeValue;
            },
            inputArray() {
               // console.log(this.treeValue.packet);
                let inputArray;
                if (typeof this.treeValue.packet === "object") {
                    inputArray = {...this.treeValue.packet};
                    // console.log(this.treeValue, inputArray, inputArray.packetinfo);
                    //let front = {};
                    // if (typeof inputArray.packetinfo != "undefined") {
                    //   front["packetinfo"] = inputArray["packetinfo"];
                    // }
                    // if (typeof inputArray["263"] != "undefined") {
                    //   front["263"] = inputArray["263"];
                    // }

                    // if (Object.keys(front).length > 0) {
                    //this.$delete(inputArray, "packetinfo");
                    //this.$delete(inputArray, "263");

                    //  inputArray = { ...front, ...inputArray };
                    //}
                } else {

                    inputArray = this.treeValue.packet;
                }
                if(this.type=="Explorer_Speedtest"){ //remove unnecessary
                  if(typeof inputArray["message"]!=='undefined'){
                    delete inputArray["message"];
                  }
                }else if(this.type=="Explorer_http_download"){
                  if(typeof inputArray["host"]!=='undefined' && inputArray["host"]==" "){ // sometime empty is coming!
                    delete inputArray["host"];
                  }
                }
                //console.log(this.type)
                //console.log(inputArray)
                return inputArray;
            },
            packetinfo() {
                //console.log("packet");
                let inputArray = {...this.treeValue.packet};
                //console.log(this.treeValue, inputArray, inputArray.packetinfo);
                let front = {};
                if (typeof inputArray.packetinfo != "undefined") {
                    front["packetinfo"] = inputArray["packetinfo"];
                }
                // if (typeof inputArray["263"] != "undefined") {
                //   front["263"] = inputArray["263"];
                // }

                // if (Object.keys(front).length > 0) {
                //   this.$delete(inputArray, "packetinfo");
                //   this.$delete(inputArray, "263");

                //   //  inputArray = { ...front, ...inputArray };
                // }
                return front;
            },
            packet263() {
                let inputArray = {...this.treeValue.packet};
                // console.log(this.treeValue, inputArray, inputArray.packetinfo);
                let front = {};
                // if (typeof inputArray.packetinfo != "undefined") {
                //   front["packetinfo"] = inputArray["packetinfo"];
                // }
                if (typeof inputArray["263"] != "undefined") {
                    front["263"] = inputArray["263"];
                }

                // if (Object.keys(front).length > 0) {
                //   // this.$delete(inputArray, "packetinfo");
                //   this.$delete(inputArray, "263");

                //   //  inputArray = { ...front, ...inputArray };
                // }
                return front;
            },
            attrsNames() {
              let result = this.result.treeValue.attrsNames;
              if(this.type=="RADIUS"){
                result = this.radiusAttributesCollection
              }
              if(this.type=="Explorer_SIP_CALL"){
                result = this.result.additional.sipCallAttr;
              }
                return result;
            },
            attrEnum() {
              let result = this.result.treeValue.attrEnum;
              if(this.type=="Explorer_SIP_CALL"){
                result = this.result.additional.sipCallAttrUnits;
              }
              return result
            },
            type() {
                return this.result.treeValue.type;
            },
        },

    };
</script>
<style>
    #wrapper {
        width: 100%;
    }

    #c1 {
        float: left;
    }

    #c2 {
        float: right;
    }
</style>